import "./src/styles/vendor/bootstrap/css/bootstrap.min.css"

import "./src/styles/vendor/fontawesome/css/fontawesome-all.min.css"

import "./src/styles/css/menu.css"

import "./src/styles/css/helper.css"

import "./src/styles/css/theme.css"

import "./src/styles/css/custom.css"

// trigger an immediate page refresh when an update is found
export const onServiceWorkerUpdateReady = () => window.location.reload()
